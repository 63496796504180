<template>
  <section class="mr-2">
    <v-sheet class="custom-border" transparent >
      <v-tabs class="px-5" v-model="tab" background-color="transparent" hide-slider show-arrows>
        <v-tab
          v-for="item in items"
          :key="item"
          class="text-capitalize poppins f14 fw600 secondary--text no-spacing"
          active-class="secondary-1--text"
          style="text-spacing: 0px"
        >
          {{ item }}
        </v-tab>
        <v-spacer />
      </v-tabs>
    </v-sheet>
      <v-divider />
      <v-tabs-items v-model="tab" style="background-color: transparent">
        <v-tab-item v-for="item in items" :key="item">
          <AssessmentTable
            v-if="item === 'My Assessments'"
            :assessments="assessments"
            :loading = "assessmentLoading"
            :pageCount = "pageCount"
            :page = "page"
            :paginate = "paginate"
            @refreshTable="getAssessments()"
            @page="(e) => { page = e, getMyAssessments() }"
            @paginate="(e) => { paginate = e, page = 1, getMyAssessments() }"
          />

          <AssessmentShareWithMeTable
            v-if="item === 'Shared with me'"
            :assessments="assessments"
            :new_assessment_uuid="new_assessment_uuid"
            :loading = "assessmentLoading"
            :pageCount = "pageCount"
            :page = "page"
            :paginate = "paginate"
            @page="(e) => { page = e, getSharedWithMeAssessments() }"
            @paginate="(e) => { paginate = e, page = 1, getSharedWithMeAssessments() }"
          />

          <AssessmentShareToOtherTable
            v-if="item === 'Shared to others'"
            :assessments="assessments"
            :loading = "assessmentLoading"
            :pageCount = "pageCount"
            :page = "page"
            :paginate = "paginate"
            @page="(e) => { page = e, getSharedToOthersAssessments() }"
            @paginate="(e) => { paginate = e, page = 1, getSharedToOthersAssessments() }"
          />
        </v-tab-item>
      </v-tabs-items>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import items from '../../constants/tabs/assessment/assessmentbuilder'
import AssessmentTable from "../../components/teacher/assessment/AssessmentTable.vue";
import AssessmentBuilder from "../../components/teacher/assessment/AssessmentBuilder.vue";
import AssessmentShareWithMeTable from "../../components/teacher/assessment/AssessmentShareWithMeTable.vue";
import AssessmentShareToOtherTable from "../../components/teacher/assessment/AssessmentShareToOtherTable.vue";

export default {
  components: {
    AssessmentBuilder,
    AssessmentTable,
    AssessmentShareToOtherTable,
    AssessmentShareWithMeTable,
  },
  data: () => ({
    loading: false,
    assessmentLoading: false,
    items: items,
    new_assessment_uuid: 0,
    pageCount: 1,
    page: 1,
    paginate: '10',
  }),
  computed: {
    ...mapState("instructor", {
      assessments: (state) => state.assessments,
    }),

    tab: {
      get() {
        let tab = parseInt(this.$route.query.tab);
        return tab;
      },
      set(tab) {
        this.$router.replace({ query: { tab: tab, timestamp: Date.now() } });
      },
    },
  },
  // created() {
  //   this.assessmentLoading = true;
  //   this.getInstructorAssessmentsAction().then(() => {
  //     if( this.tab === `1`) {
  //       this.showSharedWithMeAction().then(() => {
  //         this.getInstructorsToShareAction().then(() => {
  //           this.assessmentLoading = false;
  //         })
  //       })
  //     } else if( this.tab === `2`) {
  //       this.showSharedToOtherAction().then(() => {
  //         this.getInstructorsToShareAction().then(() => {
  //           this.assessmentLoading = false;
  //         })
  //       })
  //     } else {
  //       this.getInstructorsToShareAction().then(() => {
  //         this.assessmentLoading = false;
  //       })
  //     }
  //   })
  // },
  mounted() {
    if(!this.$route.query || !this.$route.query.tab) {
      this.$router.replace({ query: { tab: 0, timestamp: Date.now() } });
    } else {
      this.tab = this.$route.query.tab
      this.$router.replace({ query: { tab: this.$route.query.tab, timestamp: Date.now() } });
    }
    if(this.$route.params.assessment_uuid) {
      this.tab = 1
      this.new_assessment_uuid = this.$route.params.assessment_uuid
    }

    this.getInstructorsToShareAction()

    if(this.tab === 0) {
      this.getMyAssessments()
    }

    if(this.tab === 1) {
      this.getSharedWithMeAssessments()
    }

    if(this.tab === 2) {
      this.getSharedToOthersAssessments()
    }
  },
  methods: {
    ...mapActions("instructor", [
      "getDashboardCoursesAction",
      "getInstructorAssessmentsAction",
      "getInstructorsToShareAction",
      "showSharedToOtherAction",
      "showSharedWithMeAction",
    ]),

    getAssessments(){
      this.assessmentLoading = true
      this.getInstructorAssessmentsAction().finally(() => {
        this.assessmentLoading = false;
      })
    },

    getMyAssessments() {
      this.assessmentLoading = true
      this.getDashboardCoursesAction({ paginate: Number(this.paginate), page: this.page }).then(res => {
        this.page = res.current_page
        this.pageCount = res.last_page
        this.paginate = String(res.per_page)
        this.getInstructorAssessmentsAction().finally(() => {
          this.assessmentLoading = false;
        })
      }).catch(() => {
        this.assessmentLoading = false;
      })
    },

    getSharedWithMeAssessments(){
      this.assessmentLoading = true
      this.showSharedWithMeAction({ paginate: Number(this.paginate), page: this.page }).then(res=>{
        this.page = res.current_page
        this.pageCount = res.last_page
        this.paginate = String(res.per_page)
        this.assessmentLoading = false;
      }).catch(() => {
        this.assessmentLoading = false;
      })
    },

    getSharedToOthersAssessments(){
      this.assessmentLoading = true
      this.showSharedToOtherAction({ paginate: Number(this.paginate), page: this.page }).then(res=>{
        this.page = res.current_page
        this.pageCount = res.last_page
        this.paginate = String(res.per_page)
        this.assessmentLoading = false;
      }).catch(() => {
        this.assessmentLoading = false;
      })
    }

  },
  watch: {
    tab(val) {
      if( val == 2 ) {
        this.getSharedToOthersAssessments()
      }

      if(val == 1) {
        this.getSharedWithMeAssessments()
      }

      if(val == 0) {
        this.getMyAssessments()
      }
      
      this.pageCount = 1
      this.page = 1
      this.paginate = '10'
    },
  },
};
</script>
