<template>
  <section>
    <v-progress-linear
      v-if="loading"
        indeterminate
        color="primary"
        rounded
        height="3"
    ></v-progress-linear>
    <v-data-table
      :headers="shared_with_me_tbl"
      :items="assessments"
      :items-per-page.sync="paginate"
      class="elevation-1 poppins px-5"
      hide-default-footer
    >
      <template v-slot:item.title="{ item }">
        <v-badge
          dot
          left
          inline
          v-if="new_assessment_uuid && item.assessment.uuid === new_assessment_uuid"
          class="pl-1"
        > {{ item.assessment.title ? item.assessment.title : "Untitled"}}
        </v-badge>
        <span v-else> {{ item.title ? item.title : "Untitled"}} </span>
      </template>
      <template v-slot:item.shared_by="{ item }">
        {{ item.shared_by_user.email ? item.shared_by_user.email : '' }}
      </template>
      <template v-slot:item.date_shared="{ item }">
        {{ $dateFormat.mmDDyy(item.created_at) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon color="primary" @click="move(item.assessment)">
          <v-icon>mdi-folder-move-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    
    <FormPagination 
      :pageCount="pageCount" 
      :page="page"
      :paginate="paginate"
      @page="(e) => $emit('page', e)" 
      @paginate="(e) => $emit('paginate', e)"/>
    <!-- <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(item, i) in header"
              :key="i"
              class="f13 poppins fw500 text-uppercase secondary-2--text"
              :class="i == 0 ? 'text-left pl-10' : 'text-center'"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in assessments"
            :key="i"
            class="roboto fw500 secondary-1--text"
            :class="item.assessment.uuid === new_assessment_uuid ? 'new_row' : ''"
            v-if="item.assessment"
          >
            <td class="f14">
              <v-badge
                dot
                left
                inline
                v-if="item.assessment.uuid === new_assessment_uuid"
                class="pl-1"
              > {{ item.assessment.title }}
              </v-badge>
              <span class="pl-5">
                {{ item.assessment.title }}
              </span>
            </td>
            <td class="text-center f14">
              {{ item.shared_by_user.email }}
            </td>
            <td class="text-center f14 text-capitalize">
              {{ $dateFormat.mmDDyy(item.created_at) }}
            </td>
            <td class="text-center">
              <v-btn icon color="primary" @click="move(item.assessment)">
                <v-icon>mdi-folder-move-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    
    <!-- <v-container
        v-if="courses.length === 0 && !loading"
        color="transparent"
        class="d-flex flex-column align-center justify-center my-10"
      >
        <div class="">
          <v-img 
            :max-width="$vuetify.breakpoint.mobile?200:300" 
            :src="require('../../../assets/default/empty_drafts.png')"/>
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5 text-center">Nothing in Shared</h3>
    </v-container> -->

    <MoveDialog
      action="shared"
      :data="toShare"
      :courses="courses"
      :dialog="moveDialog"
      @close="moveDialog = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MoveDialog from "./AssessmentMoveDialog.vue";
import { shared_with_me_tbl } from '../../../constants/tblheaders/shared'

export default {
  props: {
    assessments: {
      type: Array,
      default: []
    },
    new_assessment_uuid: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageCount: {
      type: Number,
      default: 1
    },
    page: {
      type: Number,
      default: 1
    },
    paginate: {
      type: String,
      default: '10'
    },
  },
  components: {
    MoveDialog,
  },
  data: () => ({
    moveDialog: false,
    toShare: {
      title: 'Untitled'
    },
    shared_with_me_tbl,
  }),
  computed: {
    ...mapState("instructor", {
      courses: (state) => state.courses,
    }),
  },
  created() {},
  methods: {
    ...mapActions("instructor", ["getCoursesAction"]),

    move(data) {
      this.getCoursesAction().then(() => {
        this.moveDialog = true;
      });
      this.toShare = data;
    },
  },
  watch: {},
};
</script>

<style scoped>
.my-menu {
  /* margin-top: 40px; */
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 10px;
  /* right: 10px; */
  transform: translateX(-100%);
  /* box-shadow: #fff; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04) !important; */
  /* box-shadow: 0 -5px 5px -5px #333; */
  width: 13px;
  height: 13px;
  border-top: 12px solid transparent;
  border-left: 12px solid transparent;
  border-right: 13px solid #e5e5e5;
  border-bottom: 12px solid transparent;
}

</style>